import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import NoteList from "../components/NoteList/NoteList"
import { Intro } from "../components/Intro/Intro"
import { Footer } from "../components/Footer/Footer"

import "./Home.css"

const IndexPage = ({
  data: {
    allMdx: { edges },
  },
}) => {
  const notes = edges
    .filter(edge => !!edge.node.frontmatter.date)
    .map(edge => edge.node)

  return (
    <Layout>
      <SEO title="Notes" />
      <Helmet meta={[{ name: "theme-color", content: "pink" }]} />
      {/* <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div> */}

      <div className="home">
        <Intro />
        <h2>Notes</h2>
        <NoteList notes={notes} />
        <Footer />
      </div>
    </Layout>
  )
}

// excerpt(pruneLength: 2500)
export const pageQuery = graphql`
  query {
    allMdx(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          body
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
            draft
          }
        }
      }
    }
  }
`

export default IndexPage
