import React from "react"
// import { useStaticQuery, graphql } from "gatsby"
// import Img from "gatsby-image"

import "./Intro.css"

interface Props {}

export const Intro: React.FunctionComponent<Props> = () => {
  // const data = useStaticQuery(graphql`
  //   query {
  //     myFace: file(relativePath: { eq: "face.png" }) {
  //       childImageSharp {
  //         fixed(width: 125) {
  //           ...GatsbyImageSharpFixed
  //         }
  //       }
  //     }
  //   }
  // `)

  return (
    <div className="intro">
      <h2>Hello!</h2>
      <p>
        My name is Rémi, I live in San Diego. I surf and code internet things.
      </p>
      <p>
        Sometimes I tweet from{" "}
        <a href="https://twitter.com/remicarton" target="_blank">
          @remicarton
        </a> or I trumpet(?) from <a href="https://hachyderm.io/@rcarton" target="_blank">@rcarton@hachyderm.io</a>
        .
      </p>
      {/* <Img fixed={data.myFace.childImageSharp.fixed} alt="This is my face" /> */}
    </div>
  )
}
