import React from "react"
import { Link } from "gatsby"
import { NoteData } from "../Note/Note"

const isProd = process.env.NODE_ENV === "production"

import "./NoteList.css"

interface Props {
  notes: NoteData[]
}

const NoteList: React.FunctionComponent<Props> = ({ notes }) => {
  return (
    <div className="note-list">
      {notes
        .filter(n => !isProd || !n.frontmatter.draft)
        .map((note: NoteData) => (
          <div key={note.id} className="note-list-item">
            <div className="note-list-title">
              {note.frontmatter.draft && "[DRAFT]"}
              <Link to={note.frontmatter.slug}>{note.frontmatter.title}</Link>
            </div>
            <div className="note-list-timestamp">{note.frontmatter.date}</div>
          </div>
        ))}
    </div>
  )
}

export default NoteList
